/**
 * External Dependencies
 */
import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

/**
 * Internal Dependencies
 */
import ArchiveFeatures from "../components/ArchiveFeatures"
import ArticleHeader from "../components/ArticleHeader"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import parseContent from "../utils/parse-content"
import { filterPreviewContent } from "../utils/filter-preview-content"
import { translateListingPostUrls } from "../utils/url-translations"

const InspireArchive = ({ data, pageContext: { localeSettings, urlTranslations } }) => {
  let posts = filterPreviewContent(data.allWpInspire.nodes)
  posts = translateListingPostUrls(posts, urlTranslations.contentUrlTranslations)

  let title = '';
  let headline = '';
  let subheadline = '';

  if (data.post?.title) {
    // If wordpress post, take content from there
    title = data.post.title
    headline = null
    subheadline = null
  } else {
    // pull in from YAML
    let {
      inspireArchive: { headline, subheadline },
    } = localeSettings

    title = parse(headline)
  }

  return (
    <Layout isHomePage settings={localeSettings}>
      <SEO title={title} seo={data.post?.seo} />

      {headline && (
        <ArticleHeader
          backgroundColor="#350C13"
          headline={<h1>{headline}</h1>}
          subheadline={parse(subheadline)}
        />
      )}
      {!headline && parseContent(data.post.content)}

      <div className="container alignfull">
        <ArchiveFeatures posts={posts} />
      </div>
    </Layout>
  )
}

export default InspireArchive

export const pageQuery = graphql`
  query WordPressInspireArchive($locale: String!) {
    post: wpPage(slug: { eq: "inspire-listing" }, language: { locale: { eq: $locale } }) {
      id
      uri
      slug
      guid
      title
      content
      seo {
        metaDesc
        metaKeywords
        opengraphDescription
        opengraphImage {
          uri
          localFile {
            publicURL
          }
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterImage {
          uri
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
    }
    allWpInspire(
      filter: { language: { locale: { eq: $locale } }, exclusive: { ne: true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        slug
        uri
        title
        excerpt
        nodeType
        featuredVideo
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`
